.block {
  display: block;
}

.none {
  display: none;
}

.blockedCourseBanner {
  margin: 2rem;

  @media (max-width: 600px) {
    margin: 1rem;
  }
}

.blockedCourse {
  display: flex;
  align-items: flex-start;
  background-color: #35353B;
  justify-content: space-between;
  border-radius: 8px;

  @media (max-width: 600px) {
    display: block;
  }
}

.imageAndDescriptionWrapper {
  display: flex;
  align-items: flex-start;
  padding-left: 4rem;

  > img {
    width: 32%;
    height: auto;
  }

  > .descriptionWrapper {
    padding-left: 2rem;
    padding-top: 6rem;

    h1 {
      color: #FFFFFF;
      font-size: 42px;
    }
    h2 {
      color: #FFFFFF;
      font-weight: normal;
      font-size: 30px;
      padding-top: 2.5rem;
    }
  }

  @media (max-width: 600px) {
    display: block;
    justify-content: center;
    align-items: center;
    padding-left: 2.5rem;

    > img {
      width: 50%;
      height: auto;
    }
  
    > .descriptionWrapper {
      padding: 0;
      display: inline-block;
  
      h1 {
        color: #FFFFFF;
        font-size: 20px;
        padding-top: 0.5rem;
      }
      h2 {
        color: #FFFFFF;
        font-weight: normal;
        font-size: 14px;
        padding-top: 0.2rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}

.availableOnGooglePlay {
  justify-content: end;
  display: grid;
  max-width: 10%;
  margin-top: 0.7rem;
  margin-right: 0.7rem;

  .blockedCourseHeader {
    justify-content: flex-end;
    background-color: #35353B;
  
    .logoPlusdin {
      max-width: 100%;
    }
  }

  .googlePlay {
    max-width: 100%;
    padding-top: 3rem;
  }

  .qrCode {
    max-width: 100%;
    height: auto;
    padding-top: 0.5rem;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    padding-left: 0;
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    text-align: center;

    .blockedCourseHeader {
      display: none;
    }

    .googlePlay {
      max-width: 35%;
      padding-top: 0.5rem;
    }

    .qrCode {
      display: none;
    }
  }
}