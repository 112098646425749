@use 'styles/_colors.scss';

.progressBarArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progressBar {
    width: 95%;
    height: 6px;
    border: 1px solid colors.$secondary;
    border-radius: 8px;
    @media (max-width: 1200px) {
        width: 92.5%;
    }
    @media (max-width: 1000px) {
        width: 90%;
    }
    @media (max-width: 800px) {
        width: 87.5%;
    }
    @media (max-width: 600px) {
        width: 85%;
    }
    @media (max-width: 400px) {
        width: 80%;
    }
}

.progressBarContent {
    height: 6px;
    background: colors.$primary;
    border-radius: 8px;
}

.progressBarPercent {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin-left: 1rem;
}
