@use 'styles/colors';
@use 'styles/position';

$padding: 32px;
$innerPadding: 12px;
$borderRadius: 8px;
$gap: 24px;

$mobileCardMaxWidth: 240px;

.coursesListCarrouselWrapper { display: initial; box-sizing: content-box; }
.coursesListGridWrapper { display: none; }

@media (min-width: 1024px) {
  .coursesListCarrouselWrapper { display: none; }
  .coursesListGridWrapper { display: initial; }
}

.courseCard {
  height: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(49, 5, 55, 0.1);
  max-width: $mobileCardMaxWidth;

  border-radius: $borderRadius;

  margin-right: 16px;
  @media (min-width: 1024px) {
    margin: 0;
    max-width: none;
  }

  img {
    object-fit: cover;
    max-width: 100%;
    border-radius: $borderRadius $borderRadius 0 0;
    @media (max-width: 600px) {
      min-height: 160px;
    }
    @media (min-width: 1000px) {
      min-height: 140px;
    }
    @media (min-width: 1200px) {
      min-height: 200px;
    }
    @media (min-width: 1400px) {
      min-height: 300px;
    }
  }

  .body {
    padding: 16px;

    h3 {
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 8px;
    }

    p.remainingTime {
      margin-bottom: 8px;

      font-size: 0.75rem;

      color: colors.$neutral;
    }
  }
}

.coursesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gap;
}

.buttonLine {
  @include position.center;
  margin: 32px auto;
}

.expandButton {
  cursor: pointer;
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
}
