.emprestimoSimWarningContainer {
  width: 100%;
  min-height: 202px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-bottom: 2rem;

  background: #FFFFFF;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &::before {
    content: '';
    display: block;
    width: 8px;
    min-height: 100%;
    
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    
    background: #0172CB;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    padding: 1rem;

    p {
      margin: 1rem 0;
      
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 135%;
      
      color: #4A4B4C;

      b, strong {
        font-weight: 700;
      }
    }

    .button {
      max-width: 245px;

      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      color: #000;
    }
  }
}

@media (min-width: 768px) {
  .emprestimoSimWarningContainer {
    min-height: auto;
    .contentContainer {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;

      .loan-logo {
        width: 86px;
        height: 36px;
      }

      p {
        font-size: 14px;
        line-height: 150%;
      }
    }
  }
}