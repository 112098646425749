.bodyPadding {
  padding: 0 16px;
  @media (min-width: 1024px) {
    padding: 0 32px;
  }
  background: var(--background);
}

.profileArea {
  display: flex;
  flex-direction: row;
  // padding: 8px 0;
  text-transform: capitalize;
}
.profileArea h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  // letter-spacing: 0.07rem;
  padding: 0 8px;
}
.profileArea img {
  opacity: 0.35;
}

.title div {
  font-size: 30px;
}

.gridClassCourses {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
  background: var(--background);
}

.coursesInProgress {
  margin-top: 8px;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
}

.continueTitle {
  margin-bottom: 16px;
}

.homeWrapper {
  padding: 0 1rem;

  > .homeWarning {
    width: 100%;
    padding: 1rem;
    border: 1px solid #fb8c00;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(49 5 55 / 10%);
    margin-bottom: 1rem;
    text-align: center;
    display: inline-flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;

    > img {
      width: 30px;
      height: 30px;
    }
  }
}

.selectorWrapper {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 2rem !important;
  @media (min-width: 1024px) {
    margin-bottom: 72px;
    margin-top: 2rem;
    width: 50%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;
  @media (min-width: 900px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.dropdownContainer {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin-top: 1rem;
  min-height: 2rem;
  @media (min-width: 600px) {
    max-width: 50%;
  }
  @media (min-width: 800px) {
    max-width: 50%;
  }
  @media (min-width: 900px) {
    max-width: 33%;
  }
  @media (min-width: 1024px) {
    max-width: 75%;
  }
  @media (min-width: 1100px) {
    max-width: 75%;
  }
  @media (min-width: 1700px) {
    max-width: 72%;
  }
}

.dropdownHeader {
  min-height: 33px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--background-secondary);
  display: flex;
  font-weight: 400;
  font-size: 14px;
  outline: none;
  color: var(--text-other);
}
.arrow {
  margin-left: auto;
}

.dropdownList {
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
  position: absolute;
  width: 100%;
  z-index: 1000;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-secondary);
  font-weight: 400;
  font-size: 14px;
  color: var(--text-other);
  &:first-child {
    margin-top: 0.5rem;
  }
}

.dropdownListMobile {
  width: 100%;
  margin: 0 1rem;
  list-style-type: none;
  font-weight: 400;
  padding: 0.5rem 1rem;
  padding-bottom: 0.75rem;
  border-radius: 8px;
  color: var(--text-other);
  background-color: var(--background-secondary);
}

.dropdownListItem {
  padding: 1rem 0.75rem;
  left: 0;
  background-color: var(--background-secondary);
}

.dropdownListItem:hover {
  background-color: var(--background-button);
}

.dropdownListItemFixed {
  list-style: none;
  padding: 1rem 0.8rem 1rem 0.75rem;
  font-weight: 1000;
  font-size: 1.3rem;
  width: fit-content;
}

@media (max-width: 900px) {
  .gridClassCourses {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .title {
    margin: 0 2rem 1rem 2rem;
  }
  .title div {
    font-size: 24px;
  }
  .gridClassCourses {
    grid-template-columns: repeat(1, 1fr);
  }
  .selector {
    max-width: 2560px;
    margin-top: 1.5rem;
    background: var(--background);
  }
}

/* TODO: remover quando tipografia for definida */
.desktopContinueTitle {
  font-size: 1.5rem;
  font-weight: 400;
}

.mobileCoursesTitle {
  font-size: 1.5rem;
  font-weight: 400;
}

.desktopCoursesTitle {
  font-size: 1.875rem;
  font-weight: 400;
}
