.bannerPromo {
  position: relative;
  width: 50%;
  padding: 16px 16px 0px 16px;
  margin-right: 2rem;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    background-repeat: no-repeat;
  }

  @media (max-width: 600px) {
    border-radius: 0;
    margin-top: 2rem;
    padding: 0px;
    flex-direction: row;
    width: 100%;
  }

  &.-noMobileBanner {
    @media (max-width: 640px) {
      display: none;
    }
  }

  > .bannerImage {
    width: 95%;
    height: auto;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  > .button {
    position: absolute;
    top: 75%;
    height: 39px;
    max-width: 300px;

    @media (max-width: 600px) {
      top: 70%;
      width: 50%;
      height: 39px;
    }
  }
}

.flexClassWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 2.5rem;

  @media (max-width: 600px) {
    padding-left: 1rem;
  }

  &.-noMobileBanner {
    @media (max-width: 640px) {
      padding-bottom: 1rem;
    }
  }

  .seeTrophyContainer {
    width: 100%;
    margin-top: 5rem;
    margin-right: 2.5rem;

    @media (max-width: 650px) {
      margin-right: 1rem;
      margin-top: 2rem;
    }

    .seeTrophyButton {
      width: 100%;
    }
  }
}

.gridClassHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;
  margin-bottom: 26px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 6%), 0px 4px 6px rgb(0 0 0 / 10%);
  @media (min-width: 600px) {
    padding: 0 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 1rem 1rem;
  }
}

.headerBar {
  padding-top: 0;
}

.headerBar span {
  font-size: 30px;
}

.headerBar hr {
  background-color: var(--backgroud-header-home);
  border: 0.25rem solid var(--backgroud-header-home);
  border-radius: 8px 0px 0px 8px;
}

.notificationComponent {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #f9971e;

  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: white;
  letter-spacing: 0.07em;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: -8px;
  left: 25px;
}

.trophyArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  padding-top: 0.5rem;
  cursor: pointer;
  @media (max-width: 600px) {
    padding-top: 1.5rem;
  }
}

.trophyArea span {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.3125rem;
  letter-spacing: 0.07rem;
}

.trophyImgArea {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.trophyImgArea img {
  width: 36px;
  height: 36px;
}

@media (max-width: 600px) {
  .headerBar {
    padding-top: 0;
  }
  .headerBar span {
    font-size: 24px;
  }
  .headerBar hr {
    border: 0.125rem solid var(--backgroud-header-home);
  }

  .trophyArea span {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}
